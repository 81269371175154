import React from "react";
import Modal from "react-bootstrap/Modal";
import "./popup.css";
import glaucomaImgModal from "../../../../static/img/glaucomaImgModal.png";
const Popup = (props) => {
  const { Show, CloseModelHandler, glaucoma, youtube } = props;
  const handleClose = (e) => {
    e.preventDefault();
    CloseModelHandler();
  };
  return (
    <>
      {glaucoma && (
        <div onClick={handleClose}>
          <Modal show={Show} onHide={handleClose}>
            <Modal.Body>
              <div className="glaucomaImgModal">
                <img src={glaucomaImgModal} alt="" className="img-fluid" />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {youtube && (
        <div onClick={handleClose}>
          <Modal show={Show} onHide={handleClose} className="youtube">
            <Modal.Body>
              <iframe
                src={`https://www.youtube.com/embed/${youtube}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Popup;
