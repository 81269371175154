import React from "react";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";

import logo from "../../../../static/img/Header-Logo.png";
const LoginSelect = (props) => {
  const { LoginSelectShow, setLoginSelectShow } = props;
  const CloseModal = () => {
    setLoginSelectShow(false);
  };
  return (
    <>
      <Modal show={LoginSelectShow} onHide={CloseModal} className="LoginModal">
        <Modal.Body>
          <div className="modelLoginContainer">
            <div className="row mx-0">
              <div className="col-12 text-center headBG">
                <h2 className="sparcsHead">SPARCS</h2>
                <p className="visionCenterPara mb-0">VISION CENTER</p>
                <p className="poweByPara mb-1">
                  Powered by <img src={logo} alt="logo" />
                </p>
              </div>

              <div className="col-12 text-center">
                <p className="phoneNoPara2 my-4">Log In As:</p>
                <button className="physicianBtn mb-3">Physician</button> <br />
                <button className="PatientBtn mb-4">Patient</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginSelect;
