import React from "react";
import YoutubeOverlay from "../../../../static/img/YoutubeOverlay.png";
import Popup from "../../../common/Modal/EyeDisease/Popup";

const Youtube = (props) => {
  const { youtubeEmbedId } = props;

  const [Show, setShow] = React.useState(false);
  const ConnectWallet = (e) => {
    setShow(true);
  };
  const CloseModel = () => {
    setShow(false);
  };
  const PopupValue = {
    Show: Show,
    CloseModelHandler: CloseModel,
    youtube: youtubeEmbedId,
    glaucoma: false,
  };
  return (
    <>
      <Popup {...PopupValue} />
      <div className="col-md-12 px-0 mt-5">
        <div className="YoutubeOverlay">
          <img src={YoutubeOverlay} alt="" onClick={ConnectWallet} />
        </div>
      </div>
    </>
  );
};

export default Youtube;
