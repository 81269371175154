import { React, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./Home.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";

function Home(props) {
  const {
    clickHereToSeeHo,
    welcomeToTheContr,
    theSpaethRichmanC,
    sparcs,
    visionCenter,
  } = props;

  return (
    <Fragment>
      <main className="main1">
        <Header {...headerData}/>
        {/* <!-- Section Banner --> */}
        <section className="BannerSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 mx-auto max-width">
                <div className="row">
                  <div className="col-md-12">
                    <h1 className="BannerHeading mt-3">
                      <span>{sparcs}</span>
                      <p className="BannerSub-heading">{visionCenter}</p>
                    </h1>
                    <div className="BannerContent shadow-sm border-R8 mt-5 mt-lg-0">
                      <h5 className="BannerContent-heading dynamicTextHeading">
                        {welcomeToTheContr}
                      </h5>
                      <p className="BannerContent-descrpt dynamicText">
                        {theSpaethRichmanC}
                      </p>
                    </div>
                    <div className="BannerAlert shadow-sm">
                    <Link to="/">
                      <span className="mr-4">
                          <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      <span className="BannerAlert-descpt dynamicText">
                          {clickHereToSeeHo}
                      </span>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Section Banner --> */}
        <Footer {...footerData}/>
        
      </main>
    </Fragment>
  );
}

export default Home;
