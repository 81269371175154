import React from "react";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";
import logo from "../../../../static/img/Header-Logo.png";
import LoginSelect from "./LoginSelect";
const LoginCode = (props) => {
  const [LoginSelectShow, setLoginSelectShow] = React.useState(false);
  const { LoginCodeShow, setLoginCodeShow } = props;
  const CloseModal = () => {
    setLoginCodeShow(false);
  };
  // Open Login Select Modal
  const ConnectLoginSelect = () => {
    setLoginCodeShow(false);
    setLoginSelectShow(true);
  };
  const LoginSelectValue = {
    LoginSelectShow: LoginSelectShow,
    setLoginSelectShow: setLoginSelectShow,
  };
  return (
    <>
      <LoginSelect {...LoginSelectValue} />
      <Modal show={LoginCodeShow} onHide={CloseModal} className="LoginModal">
        <Modal.Body>
          <div className="modelLoginContainer">
            <div className="row mx-0">
              <div className="col-12 text-center headBG">
                <h2 className="sparcsHead">SPARCS</h2>
                <p className="visionCenterPara mb-0">VISION CENTER</p>
                <p className="poweByPara mb-1">
                  Powered by <img src={logo} alt="logo" />
                </p>
              </div>

              <div className="col-12 text-center">
                <p className="phoneNoPara">
                  We just sent you a text! <br />
                  Please enter your 4 digit code
                </p>

                <div class="input-group my-4 mb-5 px-sm-5">
                  <div class="input-group-prepend iconWrapper">
                    <span
                      class="input-group-text iconWrapper"
                      id="basic-addon1"
                    >
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control signInput"
                    placeholder="1234"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="col-12 px-0 borderraduis">
                <button className="LoginBtn2" onClick={ConnectLoginSelect}>
                  LOG IN <i class="fa fa-angle-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginCode;
