import React from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./SelectEyeModal.css";
const SelectEyeModal = (props) => {
  const { Show, testType, setShow, setTestType } = props;
  const handleClose = () => {
    setShow(false);
    setTestType("Right");
  };
  return (
    <>
      <div>
        <Modal show={Show} onHide={handleClose} className="begintest">
          <Modal.Body>
            <div className="modalContainer">
              <div className="row mx-0">
                <div className=" col-md-12 px-0 borderBottom">
                  <h3 className="BannerContent-heading border-bottom-0">
                    Quick Tips
                  </h3>
                </div>
                <button class="cross" onClick={handleClose}>
                  +
                </button>
              </div>
              <div className="row mx-0">
                <div className="col-12 pr-0">
                  <ol className="quicktipList BannerContent-descrpt">
                    <li>The test usually lasts a few minutes.</li>
                    <li>If you're not sure about the answer, just guess!</li>
                  </ol>
                </div>
                <div className="col-12 px-0">
                  <h3 className="BannerContent-heading mt-3 borderBottom">
                    Test Setup
                  </h3>
                  <ol className="quicktipList pl-4 ml-3">
                    <li>
                      Cover your <span className="boldfont">{testType} Eye</span>{" "}
                      throughout the test.
                    </li>
                    <li>
                      Before beginning the test, press
                      <span className="boldfont"> F11</span> to make your
                      browser full-screen.
                    </li>
                  </ol>
                </div>
                <div className="col-12 text-center">
                  <Link to="/begin-test" class={`nav-link`} className="btnBegin">
                    Begin Test
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SelectEyeModal;