import React from "react";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";
import logo from "../../../../static/img/Header-Logo.png";
import LoginCode from "./LoginCode";
const LoginModal = (props) => {
  const { Show, setShow } = props;
  const [LoginCodeShow, setLoginCodeShow] = React.useState(false);

  const CloseModal = () => {
    setShow(false);
  };
  // Open Login Code Modal
  const ConnectLoginModal = (e) => {
    e.preventDefault();
    setShow(false);
    setLoginCodeShow(true);
  };
  const LoginModalValue = {
    LoginCodeShow: LoginCodeShow,
    setLoginCodeShow: setLoginCodeShow,
  };
  return (
    <>
      <LoginCode {...LoginModalValue} />
      <Modal show={Show} onHide={CloseModal} className="LoginModal">
        <Modal.Body>
          <div className="modelLoginContainer">
            <div className="row mx-0">
              <div className="col-12 text-center headBG">
                <h1 className="sparcsHead">
                  <span>SPARCS</span>
                </h1>
                <p className="visionCenterPara mb-0">VISION CENTER</p>
                <p className="poweByPara mb-1">
                  Powered by <img src={logo} alt="logo" />
                </p>
              </div>

              <div className="col-12 text-center">
                <p className="phoneNoPara">Please enter your phone number</p>

                <div class="input-group my-4 mb-5 px-sm-5">
                  <div class="input-group-prepend iconWrapper">
                    <span
                      class="input-group-text iconWrapper"
                      id="basic-addon1"
                    >
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control signInput"
                    placeholder="555-555-555"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="col-12 px-0 BtnWrapper borderraduis">
                <div className="row">
                  <div className="col-6 pr-0 text-center borderraduis">
                    <button className="createBtn">Create an Account</button>
                  </div>
                  <div className="col-6 pl-0 text-center borderraduis">
                    <button className="LoginBtn" onClick={ConnectLoginModal}>
                      LOG IN{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
