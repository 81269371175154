const PraticeTestData = {
  TestInstruction: "Practice Test Instructions",
  TestInstructionList: [
    "In the test you'll be presented with a series of visual challenges. Your job is to click on the part of the screen where you see the striped image. It starts easy but it gets harder!",
    "Keep your eye focused on the central icon located in the middle of the screen throughout the test.",
    "Take the test in a room with normal light levels.",
  ],
  SelectEye: "Select an eye to begin the best",
  LeftEye: "Left Eye",
  RightEye: "Right Eye",
};

export default PraticeTestData;
