import { React, Fragment } from "react";
import {useLocation} from 'react-router-dom';
import $ from "jquery";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./ShowResult.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";

const ShowResult = (props) => {
  const { ShowResultHeading} = props;
  const location = useLocation();
  return (
    <Fragment>
    <main className="main2">
      <Header {...headerData} />
      <section className="TestInstruction mt-4">
        <div className="container-fluid">
          <div className="row">
            {/* Left Tabs */}
            <div className="col-md-2"></div>
            {/* Tabs Content */}
            <div className="col-md-10 mt-md-0 mt-3">
              <div className="row">
                <div className="col-md-10">
                  <div className="BannerContent shadow-sm border-R8 mt-2 mt-lg-0 mx-2">
                    <h5 className="BannerContent-heading dynamicTextHeading" id="AboutHeading">
                      {ShowResultHeading}
                    </h5>
                    <ul className="AboutList">
                      <li className="BannerContent-descrpt mb-2 dynamicText">TotalAttempts: {location.state.totalAttempts}</li>
                      <li className="BannerContent-descrpt mb-2 dynamicText">CorrectAttempts: {location.state.correctAttempts}</li>
                      <li className="BannerContent-descrpt mb-2 dynamicText">IncorrectAttempts: {location.state.incorrectAttempts}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer {...footerData} />
    </main>
    </Fragment>
  );
};

export default ShowResult;