const EyeDiseaseData = {
  LeftTabs: [
    { value: "Glaucoma", active: 1 },
    { value: "Cateract", active: 0 },
    { value: "Diabetic Retinopathy", active: 0 },
    { value: "Presbyopia", active: 0 },
    { value: "Macular Degeneration", active: 0 },
  ],
  RightTabs: [
    { value: "Stage 1", active: 1 },
    { value: "Stage 2", active: 0 },
    { value: "Stage 3", active: 0 },
  ],
  glaucoma: "Glaucoma",
  glaucomaisdefinedas: (
    <>
      Glaucoma is defined as a group of eye diseases characterized by damage to
      the optic nerve usuallydue to excessively high intraocular pressure (IOP).
      <br></br><br></br>If untreated, this increased pressure within the eye can lead to
      optic nerve damage resulting in progressive, permanent vision loss,
      starting with unnoticeable blind spots at the edges of the field of
      vision, progressing to tunnel vision, and then to blindness.
    </>
  ),
  glaucomaisgenerally: (
    <>
      Glaucoma is generally considered a slow-progressing disease of the eye. In
      the most common form of glaucoma, primary open-angle glaucoma, damage to
      the retinal cells occurs quite slowly. Untreated glau,coma can progress to
      blindness within several years.
    </>
  ),
  youtubeEmbedId: "zh0YrqdP0lI",
};

export default EyeDiseaseData;