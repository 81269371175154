import { React, Fragment, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./EyeDisease.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";
// Modal
import Popup from "../../common/Modal/EyeDisease/Popup";
// Sub Component
import Youtube from "./Youtube";

import StageImage from "../../../static/img/stageImg.png";
import GlaucomaImg from "../../../static/img/glaucomaImg.png";

const EyeDisease = (props) => {
  const { LeftTabs, RightTabs, glaucoma, glaucomaisdefinedas, glaucomaisgenerally, youtubeEmbedId } = props;
  const [Show, setShow] = useState(false);
  const ConnectWallet = (e) => {
    setShow(true);
  };
  const CloseModel = () => {
    setShow(false);
  };
  const PopupValue = {
    Show: Show,
    CloseModelHandler: CloseModel,
    glaucoma: true,
    youtube: false,
  };
  return (
    <Fragment>
      <main className="main2">
        <Header {...headerData} />
        {/* EyeDiseases Tabs */}
        <section className="EyeDisease mt-4">
          <div className="container-fluid">
            <div className="row">
              {/* Left Tabs */}
              <div className="col-md-2">
                <div class="nav flex-column nav-pills left-tabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {LeftTabs.map((item, i) => {
                    return (
                      <a
                        key={i}
                        class={`nav-link ${item.active === 1 && "active"}`}
                        id={`${item.value.replace(/\s/g, "")}-tab`}
                        data-toggle="pill"
                        href={`#${item.value.replace(/\s/g, "")}`}
                        role="tab"
                        aria-controls={`${item.value.replace(/\s/g, "")}`}
                        aria-selected="true"
                      >
                        {item.value}
                      </a>
                    );
                  })}
                </div>
              </div>
              {/* Tabs Content */}
              <div className="col-md-10  mt-md-0 mt-3">
                <div class="tab-content" id="v-pills-tabContent">
                  {/* 1st */}
                  <div class="tab-pane fade show active" id="Glaucoma" role="tabpanel" aria-labelledby="Glaucoma-tab">
                    <div className="row">
                      {/* Contents */}
                      <div className="col-sm-10">
                        <div class="tab-content" id="v-pills-tabContent">
                          {/* Stage 1 */}
                          <div class="tab-pane fade show active" id="Stage1" role="tabpanel" aria-labelledby="Stage1-tab">
                            <div className="StageImg StageImg1">
                              <img src={StageImage} className="img-fluid" alt="" />
                            </div>
                          </div>
                          {/* Stage 2 */}
                          <div class="tab-pane fade" id="Stage2" role="tabpanel" aria-labelledby="Stage2-tab">
                            <div className="StageImg StageImg2">
                              <img src={StageImage} className="img-fluid" alt="" />
                            </div>
                          </div>
                          {/* Stage 3 */}
                          <div class="tab-pane fade" id="Stage3" role="tabpanel" aria-labelledby="Stage3-tab">
                            <div className="StageImg StageImg3">
                              <img src={StageImage} className="img-fluid" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Right Tabs */}
                      <div className="col-sm-2 mt-sm-0 mt-3">
                        <div class="nav flex-column nav-pills left-tabs" id="v-pills-tab-r" role="tablist" aria-orientation="vertical">
                          {RightTabs.map((item, i) => {
                            return (
                              <a
                                key={i}
                                class={`nav-link ${item.active === 1 && "active"}`}
                                id={`${item.value.replace(/\s/g, "")}-tab`}
                                data-toggle="pill"
                                href={`#${item.value.replace(/\s/g, "")}`}
                                role="tab"
                                aria-controls={`${item.value.replace(/\s/g, "")}`}
                                aria-selected="true"
                              >
                                {item.value}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 2nd */}
                  <div class="tab-pane fade" id="Cateract" role="tabpanel" aria-labelledby="Cateract-tab">
                    ...
                  </div>
                  {/* 3rd */}
                  <div class="tab-pane fade" id="DiabeticRetinopathy" role="tabpanel" aria-labelledby="Diabetic Retinopathy-tab">
                    ...
                  </div>
                  {/* 4th */}
                  <div class="tab-pane fade" id="Presbyopia" role="tabpanel" aria-labelledby="Presbyopia-tab">
                    ...
                  </div>
                  {/* 5th */}
                  <div class="tab-pane fade" id="MacularDegeneration" role="tabpanel" aria-labelledby="MacularDegeneration-tab">
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Glaucoma */}
        <Popup {...PopupValue} />
        <section className="Glaucoma mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-10 ">
                    <div className="GlaucomaContent">
                      <h3 className="GlaucomaHeading dynamicTextHeading">{glaucoma}</h3>
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="GlaucomaDescrpt dynamicText">{glaucomaisdefinedas}</p>
                        </div>
                        <div className="col-lg-4 text-center">
                          <img src={GlaucomaImg} alt="" className="img-fluid mt-lg-3 GlaucomaImg" onClick={ConnectWallet}/>
                        </div>
                      </div>
                      <p className="GlaucomaDescrpt MaxW80 dynamicText">
                        {glaucomaisgenerally}
                      </p>
                      {/* youtube  */}
                      <Youtube {...{youtubeEmbedId: youtubeEmbedId}} />
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Footer */}
        <Footer {...footerData} />
      </main>
    </Fragment>
  );
};

export default EyeDisease;
