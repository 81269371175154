import {React, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import HeaderLogo from "../../../static/img/Header-Logo.png";
import "./Header.css";
import LoginModal from "../../common/Modal/Login/LoginModal";

function Header(props) {
  const { practiceTest, aboutSparcs, home, eyeDiseaseSimulator, login  } = props;
  // sidebar
  const OpenSidebarHandler = (e) => {
    e.preventDefault();
    $("#navbar-nav").addClass("add_width");
  };
  const CloseSidebarHandler = (e) => {
    e.preventDefault();
    $("#navbar-nav").removeClass("add_width");
  };
  // Open Modal
  const [Show, setShow] = useState(false);
  const ConnectWallet = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const PopupValue = {
    Show: Show,
    setShow: setShow,
  };
  return (
    <>
      <LoginModal {...PopupValue} />
      <header className="main-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="row">
                <div className="col-md-12">
                  <div className="navbar navbar-expand-lg px-sm-3 px-0">
                    <Link to="/" className="navbar-brand">
                      <img src={HeaderLogo} className="img-fluid" alt="" />
                    </Link>
                    <button className="navbar-toggler" onClick={OpenSidebarHandler}>
                      <span className="navbar-toggler-icon">&#9776;</span>
                    </button>
                    <div className="collapse navbar-collapse show header-max-width ml-4 border-R8  shadow-sm" id="my_navbar">
                      <ul id="navbar-nav" className="navbar-nav ">
                        <div>
                        <Link to="/" className="close_btn" onClick={CloseSidebarHandler}>
                          &times;
                        </Link>
                        </div>
                        <ul className="CustomNav">
                          <li><NavLink to="/">{home}</NavLink></li>
                          <li><NavLink to="/praticetest">{practiceTest}</NavLink></li>
                          <li><NavLink to="/eyedisease">{eyeDiseaseSimulator}</NavLink></li>
                          <li><NavLink to="/aboutsparcs">{aboutSparcs}</NavLink></li>
                        </ul>
                        <ul className="CustomNav ml-lg-auto">
                            <li>
                                <NavLink
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  to="/login"
                                  className="Loginbtn"
                                  onClick={ConnectWallet}
                                >
                                  {login}
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>
                                </NavLink>
                            </li>
                          </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
