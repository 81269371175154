import './App.css';
import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
//----Static Pages-------
import Home from "./components/pages/Home";
import AboutSparcs from "./components/pages/AboutSparcs";
import PraticeTest from "./components/pages/PraticeTest";
import EyeDisease from "./components/pages/EyeDisease";
import BeginTest from "./components/pages/BeginTest";
import ShowResult from "./components/pages/ShowResult";
//----Static Pages Data-------
import homeData from "./components/pages/Home/data.js";
import aboutData from "./components/pages/AboutSparcs/data.js";
import praticeTestData from "./components/pages/PraticeTest/data.js";
import eyeDiseaseData from "./components/pages/EyeDisease/data.js";
import beginTestData from "./components/pages/BeginTest/data.js";
import showResultData from "./components/pages/ShowResult/data.js";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home {...homeData}/>} />
        <Route path="/aboutsparcs" element={<AboutSparcs {...aboutData}/>} />
        <Route path="/praticetest" element={<PraticeTest {...praticeTestData}/>} />
        <Route path="/eyedisease" element={<EyeDisease {...eyeDiseaseData}/>} />
        <Route path="/begin-test" element={<BeginTest {...beginTestData}/>} />
        <Route path="/show-result" element={<ShowResult {...showResultData}/>} />
      </Routes>
    </Router>
  );
}

export default App;
