import {React, useState} from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import FooterLogo from "../../../static/img/footer-logo.png";
import "./Footer.css";

function Footer(props) {
  const { a1, a2 } = props;
  
  const [RangeSlider, setRangeSlider] = useState(2.25);
  const RangeSliderHandler = (e) => {
    setRangeSlider(e.target.value);
    let headingFontSize = parseFloat(parseFloat(e.target.value) + parseFloat("0.25"));
    $(".dynamicText").css("font-size", `${e.target.value}rem`);
    $(".dynamicTextHeading").css("font-size", `${headingFontSize}rem`);
  };
  
  return (
    <footer className="">
    <div className="container-fluid w-100">
      <div className="row">
        <div className="col-md-12">
          <div className="footer-content">
            <div className="RangeSlider mx-auto">
              <p className="RangeSlider-text">
                <span id="Increase">{a1}</span>
                <span id="Decrease">{a2}</span>
              </p>
              <input
                type="range"
                className="RangeS_Input"
                name=""
                min="1.85" 
                max="2.65" 
                step="0.2"
                value={RangeSlider}
                id="RangeSliderInput"
                onChange={RangeSliderHandler}
              />
            </div>
            <div className="footer-logo ml-auto text-sm-center text-right">
              <Link to="/">
                <img src={FooterLogo} className="img-fluid footer-logo" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;
