import { React, Fragment, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./PraticeTest.css";
//----Static Pages Data-------
import headerData from "../../common/Header/data.js";
import footerData from "../../common/Footer/data.js";

import SelectEyeModal from "../../common/Modal/PraticeTest/SelectEyeModal";

const ParticeTest = (props) => {
  const { TestInstruction, TestInstructionList, SelectEye, LeftEye, RightEye } = props;
  const [Show, setShow] = useState(false);
  const [testType, setTestType] = useState("Right");

  const ConnectWallet = (name) => {
    setShow(true);
    setTestType(name);
  };
  const PopupValue = {
    Show: Show,
    testType: testType,
    setShow: setShow,
    setTestType: setTestType,
  };
  return (
    <Fragment>
    <main className="main2">
      <Header {...headerData} />
      {/* TestInstruction */}
      <section className="TestInstruction mt-4">
        <div className="container-fluid">
          <div className="row">
            {/* Left Tabs */}
            <div className="col-md-2"></div>
            {/* Tabs Content */}
            <div className="col-md-10 mt-md-0 mt-3">
              <div className="row">
                <div className="col-md-10">
                  <div className="BannerContent shadow-sm border-R8 mt-2 mt-lg-0 mx-2">
                    <h5 className="BannerContent-heading dynamicTextHeading" id="testinstruction">
                      {TestInstruction}
                    </h5>
                    <ol className="testInstrcUL">
                      {TestInstructionList.map((item, id) => {
                        if(id == 0)
                        {
                        return (<li key={id} className="BannerContent-descrpt mb-4 dynamicText">{item}</li>);
                        }
                        else
                        {
                        return (<li key={id} className="BannerContent-descrpt BannerContent-descrpt-regular mb-4 dynamicText">{item}</li>);		
                        }
                      })}
                    </ol>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* SelectTest */}
      <SelectEyeModal {...PopupValue} />
      <section className="SelectTest mt-sm-4">
        <div className="container-fluid">
          <div className="row">
            {/* Left Tabs */}
            <div className="col-md-2"></div>
            {/* Tabs Content */}
            <div className="col-md-10 mt-md-0 mt-3">
              <div className="row">
                <div className="col-md-10">
                  <div className="SlectTestTxt">
                    <p className="BeginTest dynamicTextHeading">{SelectEye}</p>
                  </div>
                  <div className="BeginTestBtn mt-sm-5">
                    <a href="javascript:void(0);" className="shadow dynamicText" onClick={(e) => ConnectWallet('Right')}>
                      {LeftEye}
                    </a>
                    <a href="javascript:void(0);" className="shadow dynamicText" onClick={(e) => ConnectWallet('Left')}>
                      {RightEye}
                    </a>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer {...footerData} />
    </main>
    </Fragment>
  );
};

export default ParticeTest;
