const AboutData = {
  AboutHeading: "About the SPARCS Contrast Sensitivity Test",
  AboutList: [
    "The SPARCS contrast sensitivity test is a new eye exam that focuses specifically on the ability to perceive contrast between light and dark. Developed under the direction of Dr. George Spaeth, SPARCS is currently being tested both as a measure of general eye health and as a preventive tool in relation to specific ocular diseases.",
    "SPARCS was developed with the goal of increasing access in the US, and globally, to a reliable, inexpensive and safe tool to help in the prevention of avoidable eye disease. Already tested in significant clinical trials, SPARCS continues to be refined and tested in reseach centers around the world in order to verify its validity and to optimize its form and delivery.",
    "SPARCS is in the process of being adapted for licensed use. To learn more about SPARCS and how it may be of benefit to you, your patients or your subscribers, please contact Eric Spaeth at eric@sparcscontrastcenter.com.",
  ],
};

export default AboutData;
