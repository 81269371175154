const homeData = {
    clickHereToSeeHo: <>CLICK HERE TO SEE HOW SPARCS IS HELPING DOCTORS TAKE CARE OF PATIENTS DURING THE COVID-19 PANDEMIC!</>,
    practiceTest: "Practice Test",
    aboutSparcs: "About SPARCS",
    home: "Home",
    eyeDiseaseSimulator: "Eye Disease Simulator",
    login: "Login",
    welcomeToTheContr: "Welcome to the Contrast Sensitivity Test Center",
    theSpaethRichmanC: <>The Spaeth/Richman Contrast Sensitivity (&#x27;SPARCS&#x27;) test SPARCS is an internet-based contrast measurement tool developed by Dr. George Spaeth of Philadelphia, Pennsylvania. The test is available to doctors and other healthcare professionals around the world.</>,
    a1: "a",
    a2: "A",
    sparcs: "SPARCS",
    visionCenter: "VISION CENTER",
};

export default homeData;