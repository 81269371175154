import { React, useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import "./BeginTest.css";
import Beginimg from "../../../static/img/begintest.png";
import blankImg from "../../../static/img/blanktest.png";
const BeginTest = (props) => {
  const {clickHere, letsBegin, exitTest} = props;
  {
    /*
    noOfRounds = 5
    noOfAttemptsPerRound = 4
    opacity - greyness of the block (70%)
    fraction - for how long block should be displayed (500ms)
  
  
    initiateTest - "AlgorithmNextBlock" will be called and block will be displayed.
  
  
    handleClick - handles the user clicks and decide whether clicked block is correct or not, log the result. Execute algorithm to decide where to show the next block.
  
  
    algoNextBlock - to decide based on the current or previous results, 
      - which block to be shown next
      - can be decided to end the test
  
      - increment noOfAttemptsPerRound
      - If noOfAttemptsPerRound > 4 then 
        {
          increment noOfRounds
          noOfAttemptsPerRound = 0
          opacity -= 10
          fraction -= 100
        }
      - if noOfRounds >=5 then
        {
          call endTest
        }
  
      - setTimeout for HandleClick
      - setFractionTimer to hide the block
  
    endTest - to evaluate the results and display the summary of result page
  
    timeoutMethod - if user doesn't respond, it will be automatically call "AlgorithmNextBlock"
  
    hideBlock - will be excuted after Fraction and hide the lined block
    */
  }
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [correctAttempts, setCorrectAttempts] = useState(0);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [examStart, setExamStart] = useState(false);
  const navigate = useNavigate();

  const [noOfRounds, setNoOfRounds] = useState(2);
  const [noOfAttemptsPerRound, setNoOfAttemptsPerRound] = useState(5);
  const [opacity, setOpacity] = useState(70);
  const [fraction, setFraction] = useState(100);
  const [userClickTimeOut, setuserClickTimeout] = useState(5000);

  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const initiateTest = () => {
    setExamStart(true);
  } 

  const stopTest = () => {
    setExamStart(false);
    navigate("/show-result",{state:{'totalAttempts':totalAttempts,'correctAttempts':correctAttempts,'incorrectAttempts':incorrectAttempts}});
  }

  const algoNextBlock = () => {
    if(examStart)
    {
      var randomImage = randomInt(0, 4 - 1);
      $('.clickImage').attr('src',document.getElementById("blankImage").src);
      $(".clickImage").removeClass('active');
      //-------------
      document.getElementById("clickImg" + randomImage).src = document.getElementById("clickableImage").src;
      $("#clickImg" + randomImage).addClass('active');
      //--------------
      let hideBlockTimeout = null;
      clearTimeout(hideBlockTimeout);
      hideBlockTimeout = setTimeout(hideBlock, fraction);
      //let userClickTimeoutv = setTimeout(timeoutMethod, userClickTimeOut);
    }
  }

  const hideBlock = () => {
    $('.clickImage').attr('src',document.getElementById("blankImage").src);
  }

  const handleClick = (selID) => {
    if(examStart)
    {
      setTotalAttempts(totalAttempts + 1);
      let element = document.getElementById(selID);
      if(element.classList.contains('active'))
      {
        setCorrectAttempts(correctAttempts + 1);
      }
      else
      {
        setIncorrectAttempts(incorrectAttempts + 1);
      }
      $(".clickImage").removeClass('active');
    }
  };

  const timeoutMethod = () => {
    if(examStart)
    {
      setTotalAttempts(totalAttempts + 1);
      setIncorrectAttempts(incorrectAttempts + 1);
      $(".clickImage").removeClass('active');
    }
  };

  useEffect(() => {
    if(examStart)
    {
      algoNextBlock();
      const interval = setInterval(() => {
        timeoutMethod();
      }, userClickTimeOut);
      return () => clearInterval(interval);
    }
    
  });

  return (
    <section className="begintestsection">
      <div className="container-fluid">
        {/* Exit Test */}
        <div className="exittest shadow">
          <div className="left_existtest">
            <i className="fa fa-info-circle mr-3" aria-hidden="true"></i>
            <label className="mb-0 mr-3">
              Set your screen scale factor:{" "}
            </label>
            <select name="screen-scale-factor" id="screenScaleFactor">
              <option value="100">100%</option>
            </select>
            <span id="totalclickresult" className="mb-0 ml-3 mr-3"><span id="totalAttemptsg">{totalAttempts}</span> : <span id="correctAttemptsg">{correctAttempts}</span> : <span id="incorrectAttemptsg">{incorrectAttempts}</span></span>
          </div>
          <div className="right_existtest">
            <span className="RightExisttestBtn" onClick={stopTest}>
            {exitTest}
            </span>
          </div>
        </div>
        {/* first row */}
        <div className="row" style={{padding: "5px"}}>
          <div className="col-md-5 col-4 px-0 borderTop borderBottom borderLeft borderRight" id="click0"><img id="clickImg0" src={blankImg} className="Beginimg clickImage" onClick={(e) => handleClick('clickImg0')} /></div>
          <div className="col-md-2 col-4"></div>
          <div className="col-md-5 col-4 px-0 borderTop borderBottom borderLeft borderRight" id="click1"><img id="clickImg1" src={blankImg} className="Beginimg clickImage" onClick={(e) => handleClick('clickImg1')} /></div>
          {/* btn */}
          <div className="col-md-12 text-center">
            {examStart && (
              <div className="beginbtnwrapper">
                <span className="BeginBtntext">EXIT TEST</span>
                <button className="BeginBtn" onClick={stopTest} >
                  <span className="">{clickHere}</span>
                </button>
              </div>
            )}
            {!examStart && (
            <div className="beginbtnwrapper">
              <span className="BeginBtntext">{letsBegin}</span>
              <button className="BeginBtn" onClick={initiateTest}>
                <span className="">{clickHere}</span>
              </button>
            </div>
            )}
          </div>
          {/* 2nd row */}
          <div className="col-md-5 col-4 px-0 borderTop borderBottom borderLeft borderRight" id="click2"><img id="clickImg2" src={blankImg} className="Beginimg clickImage" onClick={(e) => handleClick('clickImg2')} /></div>
          <div className="col-md-2 col-4"></div>
          <div className="col-md-5 col-4 px-0 borderTop borderBottom borderLeft borderRight" id="click3"><img id="clickImg3" src={blankImg} className="Beginimg clickImage" onClick={(e) => handleClick('clickImg3')} /></div>
        </div>
        <div className="row" style={{display: "none"}}>
          <div className="col-md-12 col-4 px-0 border"><img id="clickableImage" src={Beginimg} /></div>
          <div className="col-md-12 col-4 px-0 border"><img id="blankImage" src={blankImg} /></div>
        </div>
      </div>
    </section>
  );
};

export default BeginTest;
